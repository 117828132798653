/**
 * 注册所有组件
 * 直接使用组件
 */

import Vue from 'vue';

const requireComponent = require.context('./components', true, /[a-z0-9]+\.(jsx?|vue)$/i);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .substr(fileName.lastIndexOf('/') + 1)
    // Remove the file extension from the end
    .replace(/\.\w+$/, '');
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});
