import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/main.vue';
import Login from '../views/Login.vue';
import childDomain from '../views/childDomain.vue';
import LoginLogger from '../views/LoginLogger.vue';
import HandleLogger from '../views/HandleLogger.vue';
import googleCheck from '../views/googleCheck.vue';
import LOGIN_TOKEN, { getToken } from '@/utils/storge';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/loginLogger',
        name: 'loginLogger',
        component: LoginLogger,
      },
      {
        path: '/handleLogger',
        name: 'handleLogger',
        component: HandleLogger
      },
      {
        path: '/childDomain',
        name: 'childDomain',
        component: childDomain,
      },
      {
        path: '/googleCheck',
        name: 'googleCheck',
        component: googleCheck,
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  if (Object.is(to.path, '/login')) {
    next();
    return;
  }
  if (getToken(LOGIN_TOKEN)) {
    next();
  } else {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }
});
export default router
