/** eslint-disable */
const LOGIN_TOKEN = 'login_token';

function setToken(token) {
  localStorage.setItem(LOGIN_TOKEN, token);
}
function getToken() {
  return localStorage.getItem(LOGIN_TOKEN);
}
function rmToken() {
  localStorage.removeItem(LOGIN_TOKEN);
}
export default LOGIN_TOKEN;
export { setToken, getToken, rmToken };
