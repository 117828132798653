
import axios from './request';


// 登录
export const toLogin = ({ email, password, google }) => axios({
    url: '/user/auth',
    method: 'post',
    data: {
        email,
        password,
        google,
    },
});

// 获取用户信息
export const getUserInfo = () => axios({
    url: '/user/profile/',
    method: 'get',
});

// 用户登出
export const logout = () => axios({
    url: '/user/logout/',
    method: 'post',
});
// 修改密码
export const updatePwd = ({ oldPassword, password }) => axios({
    url: '/user/resetpwd/',
    method: 'post',
    data: { oldPassword, password },
});

// 登录日志
export const loginLogList = ({
    page, limit,
    start_date, end_date,
}) => axios.get('/logs/login/lists', {
    params: {
        page, limit,
        start_date, end_date,
    }
});


// 操作日志
export const operLogList = ({
    page, limit,
    start_date, end_date,
}) => axios.get('/logs/operation/lists', {
    params: {
        page, limit,
        start_date, end_date,
    }
});


// 主域名列表
export const mainDomainList = ({
    page, limit,
    start_date, end_date,
    pid, sub_host, states
}) => axios.get(
    '/subhost/lists', {
    params: {
        page, limit,
        start_date, end_date,
        pid, sub_host, states
    },
},
);

// 删除主域名
export const deleteDomain = (data) => axios({
    url: '/subhost/delete',
    method: 'post',
    data,
    hasLoading: data.hasLoading || false
})
// 新增主域名
export const addMainDomain = ({ subhosts }) => axios({
    url: '/subhost/adds',
    method: 'post',
    data: {
        subhosts
    },
});

// 域名ns查询
export const queryDomainNs = ({ subhost }) => axios.get('/subhost/checkns', {
    params: {
        subhost
    }
});


// 修改备注
export const updateRemark = ({ subhost, remark }) => axios({
    url: '/subhost/remark',
    method: 'post',
    data: {
        subhost,
        remark
    },
});

// 子域名列表
export const subDomainList = (data) => axios.get('/host/lists', {
    params: {
        ...data
    }
})

// 新增子域名
export const addNewSubDomain = (data) => axios({
    url: '/host/add',
    method: 'post',
    data,
})

// 记录类型列表
export const recordTypeList = () => axios.get('/dnstype');

// 线路（isp）列表http:/domain/api/front/dnstline
export const dnstlineList = () => axios.get('/dnsline');

// 编辑子域名
export const editChildDomain = ({ id, data }) => axios({
    method: 'post',
    url: `/host/edit/${id}`,
    data
})

// 记录备用 开启/关闭

export const backup = (data) => axios({
    url: `/host/backup/${data.id}`,
    method: 'post',
    data
});

// 停用记录 http:/domain/api/front/host/disable/{id}

export const stopRecord = (id, hasLoading) => axios({
    url: `/host/disable/${id}`,
    method: 'post',
    hasLoading: hasLoading || false
});
// 启用记录 http:/domain/api/front/host/enable/{id}
export const startRecord = (id, hasLoading) => axios({
    url: `/host/enable/${id}`,
    method: 'post',
    hasLoading: hasLoading || false
});

// 删除记录 http:/domain/api/front/host/delete/{id}
export const delRecord = ({ password, id, hasLoading}) => axios({
    url: `/host/delete/${id}`,
    method: 'post',
    data: {
        password
    },
    hasLoading: hasLoading || false
});

// 记录
export const remark = ({id, remark}) => axios({
    url: `/host/remark/${id}`,
    method: 'post',
    data: {
        remark
    }
})

// 查询监控配置 http:/domain/api/front/host/getMonitor/23
export const getMonitor = (id) => axios.get(`host/getMonitor/${id}`);

// 监控设置
export const monitorSetting = ({ id, data }) => axios({
    url: `/host/monitor/${id}`,
    method: 'post',
    data,
})

// 监控日志
export const monitorLog = ({ page, limit, domain, isSubKey }) => axios.get('/host/MonitorLogger', {
    params: {
        page, limit, domain, isSubKey
    }
})

// 查询ns
export const domainNS = (subhost) => axios.get('/subhost/checkns', {
    params: {
        subhost
    }
})


// 创建秘钥
export const createMY = () => axios({
    url: '/google/codes',
    method: 'post'
})

// 绑定验证码
export const bindCode = ({ code, token }) => axios({
    url: '/google/binds',
    method: 'post',
    data: {
        code, token
    }
})