<template>
  <div class="home">
    <div style="width: 1348px; height: 40px; margin: 0 auto">
      <div
        style="
          background-color: white;
          width: 100px;
          height: 40px;
          line-height: 40px;
        "
      >
        登录日志
      </div>
    </div>
    <div class="home-content">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="ips" label="ips" width="580">
        </el-table-column>
        <el-table-column prop="created_at" label="最后操作时间"> </el-table-column>
      </el-table>
      <div class="home-pagination">
        <el-pagination
          :page-size="pageSize"
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { loginLogList } from '@/utils/serviceReq';
export default {
  name: "Home",
  components: {},
  data() {
    return {
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      dialogVisible2: false,
      domaininput: "",
      domainAllinput: "",
      tableData: [],
      page: 1,
      input: "",
      multipleSelection: [],
    };
  },
  mounted() {
    this.getLogList({page: 1});
  },
  methods: {
    currentChange(e) {
      this.getLogList({ page: e });
    },
    getLogList({ page }) {
      loginLogList({page, limit: 10}).then(res => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          const { list } = data || {};
          this.tableData = list.data;
          this.total = list.total;
        } else {
        this.$message({
          message: msg,
          type: 'error',
        });
        }
      });
    },
    handleSelectionChange(e) {
      this.multipleSelection = e;
    },
    allAddDomain(e) {
      if (e === 'a') {
      this.dialogVisible2 = true;
      }
    },
    toChildDomain() {
      this.$router.push("childDomain");
    },
    allAddDomainClick() {
      const allD = this.domainAllinput.split(/[(\r\n)\r\n]+/);
      console.log('******', allD);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-content {
    width: 1300px;
    margin: 0 auto;
    background-color: white;
    padding: 24px;
  }
  .hen-flex {
    display: flex;
    align-items: center;
  }
  .hen-content {
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .home-width {
    width: 450px;
  }
  .home-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
</style>