<template>
  <div class='login-input' :style="inputStyle">
    <div>{{label}}</div>
    <div class="input-contain">
         <input
         v-model="inputVal"
          :placeholder="placeholder"
          :disabled="disabled"
           @input="inputChange"
            :type="type"
            :style="realInputStyle"
         />
         <img :src='appendIcon' />
    </div>
    <div v-if="!!bottomTip" class="bottom-tip" :style="tipStyle">{{bottomTip}}</div>
  </div>
</template>

<script>
export default {
  props: {
    inputValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    appendIcon: String,
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    inputStyle: String,
    realInputStyle: String,
    bottomTip: String,
    tipStyle: String,
  },
  data() {
    return {
      inputVal: this.inputValue,
    };
  },
  methods: {
    inputChange() {
      this.$emit('changeInput', this.inputVal);
    },
  },
  watch: {
    inputValue(val) {
      this.inputVal = val;
    },
  },
};
</script>

<style lang="scss" scoped>
    .login-input {
       text-align: left;
       :first-child {
           font-family: PingFangSC-Semibold;
            font-size: 14px;
            color: #5A607F;
            letter-spacing: 0;
            margin-bottom: 4px;
       }
       .input-contain {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
        input {
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #D9E1EC;
            border-radius: 4px;
            width: 100%;
            padding: 0 16px 0 16px;
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            color: #A1A7C4;
            letter-spacing: 0;
        }
        img {
            position: absolute;
            right: 16px;
        }
       }
       .bottom-tip {
            font-family: PingFangSC-Semibold;
            font-size: 12px;
            color: #5A607F;
            line-height: 20px;
            text-align: left;
            padding-left: 16px;
       }
    }
</style>
