<template>
  <div class="topBar">
    <div class="bar-content">
      <img class="logo-img" src="@/assets/images/home/logo.png" />
      <div class="barSelect">
        <div
          v-for="(item, index) in itemLists"
          :key="index"
          @click="clickItem(index, item.routePath)"
          :class="itemSelectIndex == index ? 'bar-item select-bg' : 'bar-item'"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- avator -->
      <el-dropdown>
        <span class="el-dropdown-link">
          <div class="avator-container">
            <!-- <img src="@/assets/images/home/avatar_bar.png" /> -->
            <span>{{ userInfo.email }}</span>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="info-top">
              <!-- <img
                class="user-avator"
                src="@/assets/images/home/avatar_bar.png"
              /> -->
              <div class="info-top-detail">
                <span>{{ userInfo.name }}</span>
              </div>
            </div>
            <div class="user-line"></div>
          <el-dropdown-item>
            <!--  -->
            <div class="info-item" @click="updatePwd">
              <img src="@/assets/images/home/info_setting.png" alt="" />
              <span>修改密码</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div class="info-item" @click="logoutClick">
              <img src="@/assets/images/home/info_logout.png" alt="" />
              <span>退出</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!--  -->
    <div class="dialog-fixed" v-if="showPwdDiag">
    <el-dialog
      title=""
      :visible.sync="showPwdDiag"
      :modal="false"
      width="532px"
    >
      <div class="dialog-content">
        <div>修改密码:</div>

        <loginInput
          :label="'用户名'"
          inputStyle="margin-bottom: 10px;"
          realInputStyle="height: 40px;"
          :inputValue="updatePwdUsername"
          disabled
        />
        <loginInput
          :label="'旧密码'"
          inputStyle="margin-bottom: 10px;"
          realInputStyle="height: 40px;"
          @changeInput="(e) => (oldPassword = e)"
          type="password"
        />
        <loginInput
          :label="'新密码'"
          inputStyle="margin-bottom: 10px;"
          realInputStyle="height: 40px;"
          @changeInput="(e) => (newPassword = e)"
          type="password"
        />
        <loginInput
          :label="'确认密码'"
          inputStyle="margin-bottom: 10px;"
          realInputStyle="height: 40px;"
          @changeInput="(e) => (confirmPassword = e)"
          type="password"
        />
        <div class="dialog-btn">
          <div @click="commitclick">提交</div>
        </div>
      </div>
    </el-dialog>
    </div>
    
    <!--  -->
  </div>
</template>

<script>
import { rmToken } from "@/utils/storge";
import { logout, getUserInfo, updatePwd } from "@/utils/serviceReq";
import { setGa } from '@/utils/config.base';

export default {
  props: {},
  data() {
    return {
      itemSelectIndex: 0,
      showPwdDiag: false,
      userInfo: {},
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      updatePwdUsername: "",
      itemLists: [
        { name: "域名", routePath: "/" },
        { name: "登录日志", routePath: "/loginLogger" },
        { name: "操作日志", routePath: "/handleLogger" },
        { name: "Google验证器", routePath: "/googleCheck" },
      ],
      pathIndex: {
        "/": 0,
        "/loginLogger": 1,
        "/handleLogger": 2,
        "/googleCheck": 3,
        "/childDomain": 0,
      },
    };
  },
  created() {
    const currentRoutePaht = this.$router.currentRoute.path;
    this.itemSelectIndex = this.pathIndex[currentRoutePaht];
    getUserInfo().then((res) => {
      const { msg, code, data } = res;
      if (code === 1) {
        this.userInfo = data;
        setGa(data.is_ga);
      } else {
        this.$message({
          message: msg,
          type: "error",
        });
      }
    });
  },
  methods: {
    clickItem(index, routePaht) {
      this.itemSelectIndex = index;
      this.$router.replace(routePaht);
    },
    logoutClick() {
      logout().then((res) => {
        const { msg, code } = res || {};
        if (code === 1) {
          rmToken();
          this.$router.replace("/login");
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    updatePwd() {
      this.showPwdDiag = true;
      this.updatePwdUsername = this.userInfo.email;
    },
    commitclick() {
      if (!this.oldPassword) {
        this.$message({
          message: "请输入旧密码",
          type: "error",
        });
        return;
      }
      if (!this.newPassword) {
        this.$message({
          message: "请输入新密码",
          type: "error",
        });
        return;
      }
      if (this.confirmPassword !== this.newPassword) {
        this.$message({
          message: "新密码和确认密码不一致",
          type: "error",
        });
        return;
      }
      this.showPwdDiag = false;
      updatePwd({
        oldPassword: this.oldPassword,
        password: this.newPassword,
      }).then((res) => {
        const { msg, code } = res || {};
        if (code === 1) {
          rmToken();
          this.$router.replace("/login");
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBar {
  width: 100vw;
  height: 60px;
  background-color: #333752;
  position: fixed;
  top: 0;
  z-index: 99;
  .el-dropdown-link {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 24px;
  }
  .bar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .logo-img {
    margin-left: 13.8px;
    margin-right: 13.8px;
  }
  .barSelect {
    width: 1070px;
    height: 100%;
    display: flex;
    .bar-item {
      width: 110px;
      color: white;
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-family: PingFangSC-Regular;
      cursor: pointer;
    }
    .select-bg {
      background-color: #4f81ff;
      height: 64px;
    }
  }

  .search-input {
    width: 285px;
    height: 40px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-left: 23px;
    span {
      color: #5a607f;
      font-size: 14px;
    }
    img {
      width: 17px;
      height: 17px;
      margin-right: 12px;
    }
  }
  .notif-container {
    margin-left: 31px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .notif-container :first-child {
    background-color: #1e5eff;
    position: absolute;
    left: 13px;
    top: -5px;
    border-radius: 50%;
    color: white;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
  }

  .avator-container {
    margin-left: 33px;
    display: flex;
    align-items: center;
    span {
      margin-left: 11px;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      font-family: Helvetica-Bold;
    }
  }

  .dialog-content {
    padding: 0 28px 28px 28px;
    margin-top: -20px;
    z-index: 99;
    // box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.6);
    :nth-child(1) {
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #131523;
    }
    :nth-child(2) {
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #131523;
      margin-top: 19px;
    }
    .dialog-btn {
      width: 100%;
      margin-top: 40px;
      display: flex;
      :first-child {
        padding: 8px 35px;
        background: #d9e4ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: #1e5eff;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.user-line {
  width: 100%;
  height: 1px;
  background-color: #d7dbec;
  margin-top: 17px;
  margin-bottom: 17px;
}
.info-item {
  height: 44px;
  width: 205px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #5a607f;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.info-item:hover {
  background-color: #ecf2ff;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #1e5eff;
  letter-spacing: 0;
  cursor: pointer;
}
  .info-top {
    display: flex;
    align-items: center;
    .user-avator {
      width: 48px;
      height: 48px;
      border-radius: 4px;
    }
    .info-top-detail {
      margin-left: 24px;
      padding-top: 10px;
      :nth-child(1) {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #131523;
        letter-spacing: 0;
      }
      :nth-child(2) {
        display: flex;
        align-items: center;
        span {
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          color: #a1a7c4;
          letter-spacing: 0;
          line-height: 20px;
          margin-right: 15px;
        }
        img {
          width: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .dialog-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
