<template>
    <div>
    <TopBar />
    <div style="padding-top: 80px;">
    <router-view/>
    </div>
    </div>
</template>

<script>
export default {
    
}
</script>