<template>
  <div class="home">
    <div style="width: 1348px; height: 40px; margin: 0 auto">
      <div
        style="
          background-color: white;
          width: 100px;
          height: 40px;
          line-height: 40px;
          border-top-left-radius: 5px;
          font-size: 13px;
          font-weight: bold;
          border-top-right-radius: 5px;
        "
      >
        谷歌验证码
      </div>
    </div>
    <div class="home-content">
      <div class="home-timeline">
        <div class="block">
          <el-timeline>
            <el-timeline-item timestamp="第一步" placement="top">
              <div @click="selectCardMs(1)">
                <el-card
                  :class="[
                    {
                      'selected-card': selectCard == 1,
                      'unselect-card': selectCard != 1,
                    },
                  ]"
                >
                  <h4>秘钥操作</h4>
                  <p>通过产生秘钥按钮产生秘钥</p>
                </el-card>
              </div>
            </el-timeline-item>
            <el-timeline-item timestamp="第二步" placement="top">
              <div @click="selectCardMs(2)">
                <el-card
                  :class="[
                    {
                      'selected-card': selectCard == 2,
                      'unselect-card': selectCard != 2,
                    },
                  ]"
                >
                  <h4>绑定操作</h4>
                  <p>通过谷歌验证器上的6位数字绑定设备</p>
                </el-card>
              </div>
            </el-timeline-item>
            <el-timeline-item timestamp="第三步" placement="top">
              <div @click="selectCardMs(3)">
                <el-card
                  :class="[
                    {
                      'selected-card': selectCard == 3,
                      'unselect-card': selectCard != 3,
                    },
                  ]"
                >
                  <h4>温馨提示</h4>
                  <p>此账号已经绑定令牌,如需其他帮助请联系管理员</p>
                </el-card>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>

        <div class="timeline-content">
          

          <div class="secord-item" v-show="selectCard != 3">
            <div
            v-if="selectCard == 1"
             style="margin-bottom:36px; margin-top:24px;">
              <el-button
            type="primary"
            @click="createCurrentMy"
            >产生秘钥</el-button
          >
            </div>
            <div id="qrcode" ref="qrcode" v-show="selectCard == 2"></div>
            <div  class="item-class">
              <div class="margin-12">{{ `code` }}</div>
              <el-input
                style="width: 220px"
                v-model="code"
                placeholder="谷歌验证器上的6位数字"
              ></el-input>
            </div>

            <div class="item-class">
              <div class="margin-12">{{ `token` }}</div>
              <el-input
                style="width: 220px"
                v-model="token"
                readonly
                placeholder="创建密钥接口返回的secret值"
              ></el-input>
            </div>

            <div style="text-align: center; margin-top: 48px;">
              <el-button v-if="selectCard == 2" type="primary" @click="comfirmClick">绑定</el-button>
            </div>
          </div>


          
        <!-- 3 -->
        <div class="other-text" v-if="selectCard == 3">
          <!-- <div>温馨提示</div>
          <div>此账号已经绑定令牌,如需其他帮助请联系管理员</div> -->
          <div>
            <el-button size="medium" type="success" icon="el-icon-check" circle></el-button>
            <div class="tip-content">恭喜,您已成功绑定令牌!</div>
          </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { createMY, bindCode } from "@/utils/serviceReq";
import QRCode from "qrcodejs2"; // 引入qrcode
import { getGa, setGa, callBack } from "@/utils/config.base";
export default {
  data() {
    return {
      createSecret: "",
      parameter: "",
      code: "",
      token: "",
      is_ga: getGa(),
      selectCard: 1,
      codeurl: "",
    };
  },
  mounted() {
    if (this.is_ga == 1) {
      // this.createCurrentMy();
      this.selectCard = 3;
    }
    callBack((flag) =>{
      this.is_ga == flag;
      this.selectCard = 3;
    })
  },
  created() {
    if (this.is_ga == 1) {
      this.selectCard = 3;
    }
  },
  methods: {
    selectCardMs(num) {
      // if (num == 2) {
      //    !this.codeurl && this.createCurrentMy();
      //   this.codeurl && this.createCode();
      // }
      // this.selectCard = num;
    },
    createCode() {
      this.$refs.qrcode && (this.$refs.qrcode.innerHTML = "");
      let qrcode = new QRCode("qrcode", {
        width: 132,
        height: 132,
        text: this.codeurl, // 二维码地址
      });
    },
    createCurrentMy() {
      createMY().then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.selectCard = 2;
          const { createSecret, parameter } = data;
          this.token = createSecret.secret;
          this.codeurl = createSecret.codeurl;
          this.createSecret = JSON.stringify(createSecret);
          this.parameter = JSON.stringify(parameter);
          this.createCode();
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    comfirmClick() {
      if (!this.code) {
        this.$message({
          message: "请输入code",
          type: "warning",
        });
        return;
      }
      if (!this.token) {
        this.$message({
          message: "请先产生秘钥",
          type: "warning",
        });
        return;
      }
      bindCode({ code: this.code, token: this.token }).then((res) => {
        const { code, msg, data } = res || {};
        if (code !== 1) {
          this.$message({
            message: msg,
            type: "error",
          });
          return;
        }
        setGa(1);
        this.is_ga = 1;
        this.selectCard = 3;
        this.createCode();
        this.$message({
          message: msg,
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-content {
    width: 1300px;
    margin: 0 auto;
    background-color: white;
    padding: 24px;
    height: 600px;
  }
  .block {
    width: 500px;
    margin-right: 60px;
    margin-top:36px;
  }
  .hen-flex {
    display: flex;
    align-items: center;
  }
  .hen-content {
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .home-width {
    width: 450px;
  }
}
.home-new-content {
  display: flex;
  .home-left {
    width: 650px;
    height: 100%;
    position: relative;
  }
  .home-right {
    width: 650px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #131523;
  }
}
.item-class {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.margin-12 {
  margin-right: 12px;
  text-align: left;
}
.my-code {
  position: absolute;
  top: 0px;
}
.other-text {
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep .el-button--medium {
    font-size: 32px;
  }
  .tip-content {
    margin-top: 24px;
    font-weight: bold;
    font-size: 18px;
  }
  // :first-child {
  //   height: 60px;
  //   line-height: 60px;
  //   border-bottom: 1px solid #999999;
  //   text-align: left;
  //   padding-left: 24px;
  //   margin: 15px;
  // }
  // :last-child {
  //   font-size: 20px;
  //   font-weight: 400;
  //   font-family: MingLiU;
  //   height: 300px;
  //   padding-top: 24px;
  // }
}

.selected-card {
  background-color: #4f81ff;
  color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}
.unselect-card {
  background-color: white;
}
.timeline-content {
  width: 100%;
  height: 300px;
  margin-left: 24px;
}
.home-timeline {
  display: flex;
  align-items: center;
}
.secord-item {
  width: 800px;
  padding:24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #qrcode {
    width: 100px;
    height: 100px;
    margin-bottom: 80px;
  }
}

::v-deep .el-timeline-item__node {
  top: 55px;
}
::v-deep .el-timeline-item__tail {
  top: 55px;
}
</style>