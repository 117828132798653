<template>
  <div class="home">
    <div class="home-content">
      <div class="hen-flex hen-content"></div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column :prop="isSubKey ? 'subKey' : 'domain'" :label="isSubKey ? '子域名' : '根域名'" width="180">
        </el-table-column>
        <el-table-column prop="logger" show-overflow-tooltip label="监控日志内容">
        </el-table-column>
        <el-table-column prop="fromNode" show-overflow-tooltip label="监控节点"> </el-table-column>
        <el-table-column prop="fromIp" label="解析ip"> </el-table-column>
        <el-table-column prop="addTime" label="时间"> </el-table-column>
      </el-table>
      <div class="home-pagination">
        <el-pagination
          :page-size="pageSize"
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
    monitorLog
} from "@/utils/serviceReq";
export default {
  name: "MonitorLog",
  components: {},
  props: {
      isSubKey: Boolean, // 是否为子域名
      domain: String,
      showPage: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getDomainList({ page: 1, sub_host: this.domain });
  },
  watch: {
    showPage(val) {
    val && this.getDomainList({ page: 1, sub_host: this.domain });
    },
  },
  methods: {
   
    getDomainList({ page, sub_host, target }) {
      monitorLog({ page, limit: 10, domain: this.domain, isSubKey: this.isSubKey ? 1 : 0 }).then(
        (res) => {
          const { code, msg, data } = res || {};
          if (code == 1) {
            const { list } = data || {};
            this.total = data.total;
            this.tableData = list;
          } else {
            this.$message({
              message: msg,
              type: "error",
            });
          }
        }
      );
    },
    currentChange(e) {
      this.getDomainList({ page: e, sub_host: this.sub_host });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-content {
    width: 90%;
    background-color: white;
    padding: 24px;
    margin: 0 auto;
  }
  .hen-flex {
    display: flex;
    align-items: center;
  }
  .hen-content {
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .home-width {
    width: 450px;
  }
  .home-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
.item-class {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.margin-12 {
  margin-right: 12px;
}
.bremb-sub {
    display: flex;
    align-items: center;
}
</style>