<template>
  <div class='login'>
      <img
      src="@/assets/images/home/login_bg.png"
      lazy-src='@/assets/images/home/login_bg.png'
      max-width='450'
      max-height='450'
       />

       <div class='login_mark'>
           <img src='@/assets/images/home/login_logo.png' />
           <LoginInput
            :label="'用户名'"
            :appendIcon="require('@/assets/images/home/icon_username.png')"
            :placeholder="'请输入用户名'"
            @changeInput='usernameChange' />

           <LoginInput
            :label="'密码'"
            :appendIcon="require('@/assets/images/home/icon_password.png')"
            :placeholder="'登录密码'"
            type='password'
            inputStyle='margin-top: 24px;'
            @changeInput='passwordChange' />

           <LoginInput
            :label="'谷歌验证码'"
            :appendIcon="require('@/assets/images/home/icon_checkcode.png')"
            :placeholder="'请输入您的谷歌验证码'"
            type='number'
            inputStyle='margin-top: 24px;'
            :bottomTip="'账号没开启的谷歌验证情况下不需要输入。'"
            @changeInput='googleChange' />

            <div class="slide-container">
              <SlideVerify ref="slidVer" @verify_pass="verify_pass" />
            </div>

       <button @click="loginClick">登录</button>
       </div>
  </div>
</template>

<script>
import { setToken } from '@/utils/storge';
import { toLogin } from '@/utils/serviceReq';
export default {
  components: {
  },
  data() {
    return {
      msg: '',
      email: '',
      password: '',
      google: '',
    };
  },
  created() {
    console.log('login');
  },
  methods: {
    usernameChange(e) {
      this.email = e;
    },
    passwordChange(e) {
      this.password = e;
    },
    googleChange(e) {
      this.google = e;
    },
    loginClick() {
      if (!this.email) {
        this.$message({
          message: '请输入用户名',
          type: 'error',
        });
        return;
      }

      if (!this.password) {
        this.$message({
          message: '请输入密码',
          type: 'error',
        });
        return;
      }
      if (!this.msg) {
        this.$message({
          message: '请滑动验证',
          type: 'error',
        });
        return;
      }

      this.$refs.slidVer.clean();
      this.msg = '';
      toLogin({ email: this.email, password: this.password, google: this.google }).then((res) => {
        const { data, code, msg } = res || {};
        if (code === 1) {
          setToken(data.token);
          this.$router.replace('/');
        } else {
          this.$message({
            message: msg,
            type: 'error',
          });
        }
      });
    },
    verify_pass() {
      this.msg = 'pass';
      console.log('~~~~~~~pass');
    },
  },
};
</script>

<style lang="scss" scoped>
    .login {
        background: #F5F6FA;
        display: flex;
        align-items: center;
        height: 100vh;
        padding-left: 156px;
        .login_mark {
            width: 540px;
            background-color: white;
            padding: 60px;
            margin-left: 103px;
            text-align: center;

            .slide-container {
              margin-top: 18px;
            }
            button {
                width: 100%;
                height: 48px;
                background: #1E5EFF;
                border-radius: 4px;
                border-radius: 4px;
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                margin-top: 46px;
            }
        }
        .login_mark img {
            margin-bottom: 24px;
        }
    }
</style>>
