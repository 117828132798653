<template>
  <div>
    <div class="item-class">
      <div class="margin-12">监控类型</div>
      <el-select
        v-model="monitorType"
        style="width: 300px"
        placeholder="请选择"
      >
        <el-option
          v-for="item in monitorTypeList"
          :key="item.name"
          :label="item.name"
          :value="item.type"
        >
        </el-option>
      </el-select>
    </div>

    <!-- http -->
    <div v-if="monitorType == 1 && monitorType !== 0">
      <div class="item-class">
        <div class="margin-12">{{ `域名` }}</div>
        <el-input
          style="width: 300px"
          v-model="domain"
          placeholder="域名"
        ></el-input>
      </div>
      <div class="item-class">
        <div class="margin-12">超时时间 ( 秒 )</div>
        <el-input
          style="width: 300px"
          v-model="timeout"
          placeholder="超时时间"
        ></el-input>
      </div>
      <div class="item-class">
        <div class="margin-12">执行监控动作</div>
        <el-radio-group v-model="action">
          <el-radio label="0">无</el-radio>
          <el-radio label="1">停止解析</el-radio>
          <el-radio label="2">切换备用IP</el-radio>
        </el-radio-group>
      </div>

      <div class="item-class" v-if="action == 2">
        <div class="margin-12">备用IP</div>
        <el-input
          style="width: 300px"
          v-model="fallback"
          placeholder="备用IP"
        ></el-input>
      </div>
    </div>
    <!--  TCP  -->
    <div
      v-if="(monitorType == 2 || monitorType == 3) && monitorType !== 0"
    >
      <div class="item-class">
        <div class="margin-12">端口号</div>
        <el-input
          style="width: 300px"
          v-model="port"
          placeholder="端口号"
        ></el-input>
      </div>

      <div class="item-class">
        <div class="margin-12">执行监控动作</div>
        <el-radio-group v-model="action">
          <el-radio label="0">无</el-radio>
          <el-radio label="1">停止解析</el-radio>
          <el-radio label="2">切换备用IP</el-radio>
        </el-radio-group>
      </div>

      <div class="item-class" v-if="action == 2">
        <div class="margin-12">备用IP</div>
        <el-input
          style="width: 300px"
          v-model="fallback"
          placeholder="备用IP"
        ></el-input>
      </div>
    </div>
    <!-- ping -->
    <div v-if="monitorType == 4 && monitorType !== 0">
      <div class="item-class">
        <div class="margin-12">超时时间 ( 秒 )</div>
        <el-input
          style="width: 300px"
          v-model="timeout"
          placeholder="超时时间"
        ></el-input>
      </div>

      <div class="item-class">
        <div class="margin-12">域名或IP</div>
        <el-input
          style="width: 300px"
          v-model="domain"
          placeholder="域名或IP"
        ></el-input>
      </div>

      <div class="item-class">
        <div class="margin-12">执行监控动作</div>
        <el-radio-group v-model="action">
          <el-radio label="0">无</el-radio>
          <el-radio label="1">停止解析</el-radio>
          <el-radio label="2">切换备用IP</el-radio>
        </el-radio-group>
      </div>

      <div class="item-class" v-if="action == 2">
        <div class="margin-12">备用IP</div>
        <el-input
          style="width: 300px"
          v-model="fallback"
          placeholder="备用IP"
        ></el-input>
      </div>
    </div>
    <!--  -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">取 消</el-button>
      <el-button type="primary" @click="domainUpdateRemark">确 定</el-button>
    </span>
  </div>
</template>

<script>
import { getMonitor, monitorSetting } from "@/utils/serviceReq";
export default {
  props: {
    id: [String, Number],
    showPage: Boolean,
  },
  data() {
    return {
      domain: "",
      timeout: "",
      monitorTypeList: [
        {
          type: 0,
          name: "监控关闭",
        },
        {
          type: 1,
          name: "HTTP",
        },
        {
          type: 2,
          name: "TCP",
        },
        {
          type: 3,
          name: "TCP OVER TLS",
        },
        {
          type: 4,
          name: "PING",
        },
      ],
      monitorType: 0,
      action: "0",
      fallback: "",
      port: "",
    };
  },
  mounted() {
    this.getMMontor();
  },
  watch: {
      showPage(val) {
         val && this.getMMontor();
      },
  },
  methods: {
    monitorSet() {
      let data = {};
      if (this.monitorType == 0) {
        data = {
          mtype: 0,
        };
      } else if (this.monitorType == 1) {
        if (!this.domain) {
          this.$message({
            message: "请输入域名",
            type: "error",
          });
          return;
        }
        if (this.action == "2" && !this.fallback) {
          this.$message({
            message: "请填写备用域名",
            type: "error",
          });
          return;
        }
        if (!this.timeout) {
          this.$message({
            message: "请输入超时时间",
            type: "error",
          });
          return;
        }
        data = {
          mtype: 1,
          domain: this.domain,
          action: this.action,
          fallback: this.fallback,
          timeout: this.timeout
        };
      } else if (this.monitorType == 2 || this.monitorType == 3) {
        if (!this.port) {
          this.$message({
            message: "请输入端口",
            type: "error",
          });
          return;
        }
        if (this.action == "2" && !this.fallback) {
          this.$message({
            message: "请填写备用域名",
            type: "error",
          });
          return;
        }
        data = {
          mtype: this.monitorType,
          port: this.port,
          action: this.action,
          fallback: this.fallback,
        };
      } else if (this.monitorType == 4) {
        if (!this.timeout) {
          this.$message({
            message: "请输入超时时间",
            type: "error",
          });
          return;
        }
        if (!this.domain) {
          this.$message({
            message: "请输入域名或IP",
            type: "error",
          });
          return;
        }
        if (this.action == "2" && !this.fallback) {
          this.$message({
            message: "请填写备用域名",
            type: "error",
          });
          return;
        }
        data = {
          mtype: Number(this.monitorType),
          domain: this.domain,
          timeout: this.timeout,
          action: this.action,
          fallback: this.fallback,
        };
      }
      monitorSetting({ id: this.id, data }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
            this.$message({
            message: '设置监控成功',
            type: "success",
          });
          this.$emit("cancel");
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    getMMontor() {
      getMonitor(this.id).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          if (!data || !data.mtype) {
              this.monitorType = 0;
              return;
          }
          const { mtype, domain, fallback, port, timeout, action } = data|| {};
          this.monitorType = Number(mtype);
          this.domain = domain;
          this.fallback = fallback;
          this.port = port;
          this.timeout = timeout;
          this.action = String(action);
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    domainUpdateRemark() {
      this.monitorSet();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-class {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.margin-12 {
  margin-right: 12px;
  min-width: 100px;
  text-align: right;
}
</style>
