<template>
  <div class="home">
    <div style="width: 1348px; height: 40px; margin: 0 auto">
      <div
        style="
          background-color: white;
          width: 100px;
          height: 40px;
          line-height: 40px;
        "
      >
        全部域名
      </div>
    </div>
    <div class="home-content">
      <div class="hen-flex hen-content">
        <div class="hen-flex">
          <el-button
            type="primary"
            style="margin-right: 15px"
            @click="dialogVisible = true"
            >添加域名</el-button
          >
          <el-button
            type="primary"
            plain
            style="margin-right: 15px"
            @click="dialogVisible2 = true"
            >批量添加域名</el-button
          >
          <el-button
            v-if="multipleSelection.length"
            type="danger"
            plain
            style="margin-right: 15px"
            @click="multipleDelete"
            >批量删除</el-button
          >
          <!-- <el-dropdown
           size="medium"
            split-button
             type="info"
              @command="allAddDomain"
             >
            更多操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">>批量添加域名</el-dropdown-item>
              <el-dropdown-item command="b">>批量导入记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
        <div class="hen-flex">
          <!-- 
          <el-dropdown
           size="medium"
            split-button
             type="info">
            全部域名
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

          <el-input
            style="margin-left: 15px; width: 200px"
            v-model="searchInput"
            @change="searchChange"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="sub_host" label="域名" width="180" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="cursor: pointer" @click="toChildDomain(scope.row)">
              {{ scope.row.sub_host }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nsstates" label="状态" width="180" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.states == 0 ? (scope.row.nsstates == 0 ? "请变更DNS服务器" : "正常") : (scope.row.states == 1 ? "待审核": "禁止解析") }}
              <el-popover placement="top" v-if="(scope.row.states == 0) && (scope.row.nsstates == 0)" trigger="hover">
                <div>
                  请至域名注册商处修改DNS服务器， 如已修改请<el-button @click="refreshNs(scope.row)" type="text">点击刷新</el-button>
                </div>
                <el-button type="text" icon="el-icon-warning" slot="reference"></el-button
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="merchat_host_count" label="记录数" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"> </el-table-column>
        <el-table-column prop="created_at" label="最后操作时间" align="center">
        </el-table-column>

        <el-table-column width="180" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="toChildDomain(scope.row)">子域名</el-button> -->
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="子域名"
              placement="top-start"
            >
              <el-button
                size="mini"
                type="primary"
                @click="toChildDomain(scope.row)"
                icon="el-icon-copy-document"
                circle
              ></el-button>
            </el-tooltip> -->

            <el-tooltip
              class="item"
              effect="dark"
              content="编辑备注"
              placement="top-start"
            >
              <el-button
                size="mini"
                type="primary"
                @click="addMainRemark(scope.row)"
                icon="el-icon-edit"
                circle
              ></el-button>
            </el-tooltip>

            <!-- <el-button size="mini" type="warning" @click="toMonitor(scope.row)" icon="el-icon-monitor" circle></el-button> -->

            <el-tooltip
              class="item"
              effect="dark"
              content="监控日志"
              placement="top-start"
            >
              <el-button
                size="mini"
                type="primary"
                @click="toMonitorLog(scope.row)"
                icon="el-icon-s-platform"
                circle
              ></el-button>
            </el-tooltip>
            <!-- <el-button type="text" @click="toChildDomain()">监控日志</el-button> -->

            <el-tooltip
              class="item"
              effect="dark"
              content="删除域名"
              placement="top-start"
            >
              <el-button
                size="mini"
                type="danger"
                @click="domainDelete(scope.row)"
                icon="el-icon-delete"
                circle
              ></el-button>
            </el-tooltip>
            <!-- <el-button size="mini" type="info" @click="domainDelete(scope.row)" icon="el-icon-more" circle></el-button> -->
            <!-- <el-button type="text" @click="toChildDomain()">解析设置</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="home-pagination">
        <el-pagination
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          @current-change="currentChange"
          :total="total"
            @size-change="handleSizeChange"
          :current-page="currentPage4"
          :page-sizes="[10, 50, 100, 500, 1000]"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="添加域名" :visible.sync="dialogVisible" width="30%">
      <el-input
        v-model="domaininput"
        placeholder="请输入要解析的域名"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDomainClick">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="密码确认" :visible.sync="dialogVisible3" width="30%">
      <el-input
        v-model="domainPwd"
        placeholder="请输入密码"
        type="password"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="deleteComfirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量添加域名 -->
    <el-dialog title="批量添加域名" :visible.sync="dialogVisible2" width="30%">
      <el-input
        v-model="domainAllinput"
        placeholder="请输入要解析的域名"
        :rows="5"
        type="textarea"
      ></el-input>
      <div style="text-align: left; margin: 12px 0px">
        批量添加域名，每行一个上限100个
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="allAddDomainClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加备注 -->
    <el-dialog title="添加备注" :visible.sync="dialogVisible5" width="30%">
      <el-input
        v-model="domainRemark"
        placeholder="备注"
        :rows="5"
        type="textarea"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible5 = false">取 消</el-button>
        <el-button type="primary" @click="domainUpdateRemark">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 监控日志 -->

    <el-dialog title="监控日志" :visible.sync="dialogVisible7" width="80%">
      <MonitorLog
        :isSubKey="false"
        :domain="domainItem.sub_host"
        :showPage="dialogVisible7"
      />
    </el-dialog>

    <!-- 监控设置 -->

    <el-dialog title="监控设置" :visible.sync="dialogVisible6" width="40%">
      <Monitor
        :id="itemId"
        @cancel="dialogVisible6 = false"
        :showPage="dialogVisible6"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  mainDomainList,
  addMainDomain,
  deleteDomain,
  updateRemark,
  domainNS,
} from "@/utils/serviceReq";
import { Loading } from "element-ui";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible5: false,
      dialogVisible7: false,
      dialogVisible6: false,
      domaininput: "",
      domainAllinput: "",
      tableData: [],
      searchInput: "",
      currentPage4: 1,
      multipleSelection: [],
      domainPwd: "",
      domainItem: {},
      domainRemark: "",
      itemId: "",
      isMultiple: false,
    };
  },
  mounted() {
    this.getDomainList({ page: 1 });
  },
  methods: {
    toMonitorLog(item) {
      this.domainItem = item;
      this.dialogVisible7 = true;
    },
    toMonitor(item) {
      this.itemId = item.id;
      this.dialogVisible6 = true;
    },
    getDomainList({ page, sub_host, limit }) {
      mainDomainList({ page, limit: limit || this.pageSize, sub_host }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          const { list } = data || {};
          this.total = list.total;
            this.currentPage4 = list.current_page;
          this.tableData = list.data;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    addMainRemark(item) {
      this.domainItem = item;
      this.dialogVisible5 = true;
    },
    addDomainClick() {
      if (!this.domaininput) {
        this.$message({
          message: "请输入域名",
          type: "error",
        });
        return;
      }
      addMainDomain({ subhosts: [this.domaininput] }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({ page: 1 });
          this.domaininput = "";
          this.dialogVisible = false;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    domainDelete(item) {
      this.domainItem = item;
      this.dialogVisible3 = true;
    },
    multipleDelete() {
      this.isMultiple = true;
      this.dialogVisible3 = true;
    },
    domainUpdateRemark() {
      // if (!this.domainRemark) {
      //   this.$message({
      //     message: "请输入备注",
      //     type: "error",
      //   });
      //   return;
      // }
      updateRemark({
        subhost: this.domainItem.sub_host,
        remark: this.domainRemark,
      }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({ page: 1 });
          this.dialogVisible5 = false;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    deleteAllDomain() {
      new Promise((resolve, reject) => {
        let count = 0;
        const loadingInstance = Loading.service({ fullscreen: true });
        const len = this.multipleSelection.length;
        this.multipleSelection &&
          this.multipleSelection.forEach((item) => {
            deleteDomain({
              subhost: item.sub_host,
              password: this.domainPwd,
              hasLoading: true,
            }).then((res) => {
              const { code, msg, data } = res || {};
              count++;

              if (code == 1) {
                if (count == len) {
                  this.getDomainList({ page: 1 });
                  this.dialogVisible3 = false;
                  this.isMultiple = false;
                  this.multipleSelection = [];
                  loadingInstance.close();
                  resolve();
                }
              } else {
                if (count == len) {
                  this.getDomainList({ page: 1 });
                  this.dialogVisible3 = false;
                  this.isMultiple = false;
                  this.multipleSelection = [];
                  loadingInstance.close();
                  this.$message({
                    message: msg,
                    type: "error",
                  });
                  reject();
                }
              }
            });
          });
      });
    },
    refreshNs(item) {
      domainNS(item.sub_host).then(res => {
        const { code, msg, data } = res || {};
        this.tableData  = this.tableData && this.tableData.map(tabItem => {
           if (item.sub_host === tabItem.sub_host) {
             return {
               ...tabItem,
              nsstates: data.code
             }
           }
           return tabItem;
        });
      })
    },
    deleteComfirm() {
      if (!this.domainPwd) {
        this.$message({
          message: "请输入确认密码",
          type: "error",
        });
        return;
      }
      if (this.isMultiple) {
        this.deleteAllDomain();
        return;
      }
      deleteDomain({
        subhost: this.domainItem.sub_host,
        password: this.domainPwd,
      }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({ page: 1 });
          this.domainPwd = "";
          this.dialogVisible3 = false;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    currentChange(e) {
      this.getDomainList({ page: e });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getDomainList({ page: 1, limit: e });
    },
    searchChange(e) {
      this.getDomainList({ page: 1, sub_host: this.searchInput });
    },
    handleSelectionChange(e) {
      console.log("*******", e);
      this.multipleSelection = e;
    },
    allAddDomain(e) {
      if (e === "a") {
        this.dialogVisible2 = true;
      }
    },
    toChildDomain(item) {
      if (item.states == 1 || item.states == 2) {
        const tipText = (item.states == 1 ? '域名审核中,如有疑问请联系客服!': '域名禁止解析,如有疑问请联系客服')
         this.$alert(tipText, {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return;
      }
      this.$router.push({
        name: "childDomain",
        query: { sub_host: item.sub_host },
      });
    },
    allAddDomainClick() {
      if (!this.domainAllinput) {
        this.$message({
          message: "请输入域名",
          type: "error",
        });
        return;
      }

      const allD = this.domainAllinput.split(/[(\r\n)\r\n]+/);
      console.log("******", allD);
      addMainDomain({ subhosts: allD }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({ page: 1 });
          this.domainAllinput = "";
          this.dialogVisible2 = false;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-content {
    width: 1300px;
    margin: 0 auto;
    background-color: white;
    padding: 24px;
  }
  .hen-flex {
    display: flex;
    align-items: center;
  }
  .hen-content {
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .home-width {
    width: 450px;
  }
  .home-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
</style>