<template>
  <div class="home">
    <div style="width: 1348px; height: 40px; margin: 0 auto">
      <div
        style="
          background-color: white;
          width: 100px;
          height: 40px;
          line-height: 40px;
        "
      >
        子域名
      </div>
    </div>
    <div class="home-content">
      <div style="margin-bottom: 36px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">全部域名</el-breadcrumb-item>
          <el-breadcrumb-item>子域名</el-breadcrumb-item>
          <el-breadcrumb-item>{{ sub_host }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="hen-flex hen-content">
        <div class="hen-flex">
          <el-button
            type="primary"
            style="margin-right: 15px"
            @click="addNewRecord"
            >添加记录</el-button
          >

          <el-button
            v-if="multipleSelection.length"
            type="success"
            style="margin-right: 15px"
            @click="openMulRecord"
            >开启</el-button
          >
          <el-button
            v-if="multipleSelection.length"
            type="warning"
            style="margin-right: 15px"
            @click="pauseMulRecord"
            >暂停</el-button
          >
          <el-button
            v-if="multipleSelection.length"
            type="danger"
            style="margin-right: 15px"
            @click="delMulRecord"
            >删除</el-button
          >
          <!-- <el-dropdown
            size="medium"
            split-button
            type="info"
            @command="allAddDomain"
          >
            更多操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">>批量添加域名</el-dropdown-item>
              <el-dropdown-item command="b">>批量导入记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>

        <div class="hen-flex">
          <!-- <el-dropdown size="medium" split-button type="info">
            主机记录
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
           <el-select style="width: 100px;" v-model="mainhostvalue" placeholder="请选择">
    <el-option
      v-for="item in mainoptions"
      :key="item.name"
      :label="item.name"
      :value="item.type">
    </el-option>
  </el-select>

          <el-input
            style="width: 200px"
            v-model="target"
            placeholder="请输入"
            @change="targetChange"
          ></el-input>
        </div>
      </div>
      <el-table
        :data="newDataList.length ? newDataList.concat(tableData) : tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="host" label="主机记录" width="150" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.is_create && !scope.row.id">
                <el-input
                  style="width: 130px"
                  v-model="scope.row.prefix_host"
                  placeholder="主机记录"
                ></el-input>
              </div>
              <div v-else-if="is_edit && currentEditId == scope.row.id">
                <el-input
                  style="width: 130px"
                  v-model="prefix_host"
                  placeholder="主机记录"
                ></el-input>
              </div>
              <div class="curlor" v-else>
                <div @click="ChildDomainEdit(scope.row)">
                  {{ scope.row.host }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="record_type" label="记录类型" align="center" width="120">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.is_create && !scope.row.id">
                <el-select
                  v-model="scope.row.dnstypes"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in recordList"
                    :key="item.id"
                    :label="`${item.types.toUpperCase()}-${item.remark}`"
                    :value="item.types"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else-if="is_edit && currentEditId == scope.row.id">
                <el-select
                  v-model="dnstypes"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in recordList"
                    :key="item.id"
                    :label="`${item.types.toUpperCase()}-${item.remark}`"
                    :value="item.types"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="curlor" v-else>
                <div @click="ChildDomainEdit(scope.row)">
                  {{ scope.row.record_type }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dnslines" label="线路类型" align="center" width="120">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.is_create && !scope.row.id">
                <el-select
                  v-model="scope.row.dnslines"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.types"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else-if="is_edit && currentEditId == scope.row.id">
                <el-select
                  v-model="dnslines"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.types"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="curlor" v-else>
                <div @click="ChildDomainEdit(scope.row)">
                  {{ scope.row.dns_line.name }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="target" label="记录值" width="240" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.is_create && !scope.row.id">
                <el-input
                  style="width: 220px"
                  v-model="scope.row.values"
                  placeholder="记录值"
                ></el-input>
              </div>
              <div v-else-if="is_edit && currentEditId == scope.row.id">
                <el-input
                  style="width: 220px"
                  v-model="values"
                  placeholder="记录值"
                ></el-input>
              </div>
              <div class="curlor" v-else>
                <div @click="ChildDomainEdit(scope.row)">
                  {{ scope.row.target }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="states" label="开启状态" width="80" align="center">
          <template slot-scope="scope">
            <div
              v-if="scope.row.id"
              @click="
                scope.row.states !== 0
                  ? toStopRecord(scope.row)
                  : toStartRecord(scope.row)
              "
              :style="
                scope.row.states === 0
                  ? 'color: red;padding: 3px 0px;cursor: pointer;'
                  : scope.row.mstate === 1
                  ? 'color: orange;padding: 3px 0px;cursor: pointer;'
                  : 'color: green;padding: 3px 0px;cursor: pointer;'
              "
            >
              {{
                scope.row.states === 0
                  ? "停用"
                  : scope.row.mstate === 1
                  ? "宕机"
                  : "启用"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="backup" label="备用" width="80" align="center">
          <template slot-scope="scope">
            <div
              v-if="scope.row.id"
              @click="qhbackup(scope.row)"
              :style="
                scope.row.backup !== 0
                  ? 'color: red;padding: 3px 0px;cursor: pointer;'
                  : 'color: green;padding: 3px 0px;cursor: pointer;'
              "
            >
              {{ scope.row.backup === 0 ? "主用" : "备用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ttl" label="TTL" align="center" width="120">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.is_create && !scope.row.id">
                <el-select
                  v-model="scope.row.dnsttl"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dnsttlList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else-if="is_edit && currentEditId == scope.row.id">
                <el-select
                  v-model="dnsttl"
                  style="width: 100px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dnsttlList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="curlor" v-else>
                <div @click="ChildDomainEdit(scope.row)">
                  {{ scope.row.ttl }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip prop="remark" label="备注">
        </el-table-column>

        <el-table-column width="200" label="操作" align="center">
          <template slot-scope="scope">
            <div
              v-if="
                (is_edit && currentEditId == scope.row.id) ||
                (scope.row.is_create && !scope.row.id)
              "
              style="display: flex"
            >
              <el-button
                type="primary"
                size="small"
                @click="addChildDomain(scope.row)"
                round
                >确定</el-button
              >
              <el-button
                type="warning"
                size="small"
                @click="cancelEdit(scope.row)"
                round
                >取消</el-button
              >
            </div>
            <div v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="监控设置"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  :type="
                    scope.row.mtype == 0
                      ? 'warning'
                      : Number(scope.row.mtype) > 0 && scope.row.mstate == 0
                      ? 'success'
                      : 'danger'
                  "
                  @click="toMonitor(scope.row)"
                  icon="el-icon-monitor"
                  circle
                ></el-button>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="监控日志"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="warning"
                  @click="toMonitorLog(scope.row)"
                  icon="el-icon-video-camera"
                  circle
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑备注"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="primary"
                  @click="ChildDomainRemark(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除子域名"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="danger"
                  @click="toDelRecord(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip
              class="item"
              effect="dark"
              content="状态开关"
              placement="top-start"
            >
              <el-button
                :type="scope.row.states == 0 ? 'info' : 'danger'"
                size="mini"
                icon="el-icon-odometer"
                circle
                @click="
                  scope.row.states !== 0
                    ? toStopRecord(scope.row)
                    : toStartRecord(scope.row)
                "
              ></el-button>
            </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="home-pagination">
        <el-pagination
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          @current-change="currentChange"
          :total="total"
          @size-change="handleSizeChange"
          :current-page="currentPage4"
          :page-sizes="[10, 50, 100, 500, 1000]"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 监控设置 -->

    <el-dialog title="监控设置" :visible.sync="dialogVisible6" width="40%">
      <Monitor
        :id="itemId"
        @cancel="setMonitorCancel"
        :showPage="dialogVisible6"
      />
    </el-dialog>
    <!--  -->
    <el-dialog
      :title="is_edit ? '修改记录' : '添加记录'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div class="item-class">
        <div class="margin-12">主机记录</div>
        <el-input
          style="width: 300px"
          v-model="prefix_host"
          placeholder="主机记录"
        ></el-input>
      </div>

      <div class="item-class">
        <div class="margin-12">记录类型</div>
        <el-select v-model="dnstypes" style="width: 300px" placeholder="请选择">
          <el-option
            v-for="item in recordList"
            :key="item.id"
            :label="`${item.types.toUpperCase()}-${item.remark}`"
            :value="item.types"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-class">
        <div class="margin-12">线路类型</div>
        <el-select v-model="dnslines" style="width: 300px" placeholder="请选择">
          <el-option
            v-for="item in lineList"
            :key="item.id"
            :label="item.name"
            :value="item.types"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-class">
        <div class="margin-12">记 录 值</div>
        <el-input
          style="width: 300px"
          v-model="values"
          placeholder="记录值"
        ></el-input>
      </div>

      <div class="item-class">
        <div class="margin-12">TTL ( 秒 )</div>
        <el-select v-model="dnsttl" style="width: 300px" placeholder="请选择">
          <el-option
            v-for="item in dnsttlList"
            :key="item.id"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addChildDomain">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="密码确认" :visible.sync="dialogVisible3" width="30%">
      <el-input
        v-model="domainPwd"
        placeholder="请输入密码"
        type="password"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="deleteComfirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加备注 -->
    <el-dialog title="添加备注" :visible.sync="dialogVisible5" width="30%">
      <el-input
        v-model="domainRemark"
        placeholder="备注"
        :rows="5"
        type="textarea"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible5 = false">取 消</el-button>
        <el-button type="primary" @click="domainUpdateRemark">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 监控日志 -->

    <el-dialog title="监控日志" :visible.sync="dialogVisible7" width="80%">
      <MonitorLog
        isSubKey
        :domain="current_domain"
        :showPage="dialogVisible7"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  subDomainList,
  recordTypeList,
  dnstlineList,
  addNewSubDomain,
  editChildDomain,
  remark,
  delRecord,
  stopRecord,
  startRecord,
  getMonitor,
  monitorSetting,
  backup,
} from "@/utils/serviceReq";
import { Loading } from "element-ui";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      pageSize: 10,
      total: 0,
      currentPage4: 1,
      dialogVisible5: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible6: false,
      dialogVisible7: false,
      domainPwd: "",
      domainRemark: "",
      prefix_host: "",
      domainAllinput: "",
      tableData: [],
      target: "",
      multipleSelection: [],
      isMultiple: false,
      recordList: [],
      lineList: [],
      dnstypes: "",
      dnslines: "",
      values: "",
      dnsttl: "",
      sub_host: "",
      is_edit: false,
      is_create: false,
      itemId: "",
      current_domain: "",
      currentEditObj: {},
      currentEditId: "",
      newDataList: [],
      newDataFlag: 0,
      dnsttlList: [
        {
          id: 100,
          name: "1分钟",
          type: 60,
        },
        {
          id: 600,
          name: "10分钟",
          type: 600,
        },
        {
          id: 1800,
          name: "30分钟",
          type: 1800,
        },
        {
          id: 3600,
          name: "1小时",
          type: 3600,
        },
        {
          id: 43200,
          name: "12小时",
          type: 43200,
        },
        {
          id: 86400,
          name: "1天",
          type: 86400,
        },
      ],
      mainoptions: [
        {
          name: '主机记录',
          type: 0
        },
        {
          name: '记录值',
          type: 1
        }
      ],
      mainhostvalue: 0,
    };
  },
  mounted() {
    const { sub_host } = this.$route.query;
    this.sub_host = sub_host;
    this.getDomainList({ page: 1, sub_host, limit: this.pageSize });
    this.getRecordList();
    this.getLineList();
  },
  methods: {
    qhbackup(item) {
      const id = item.id;
      const flag = item.backup === 0 ? true : false;
      backup({ id, backup: flag }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({
            page: 1,
            sub_host: this.sub_host,
            limit: this.pageSize,
          });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    setMonitorCancel() {
         this.getDomainList({
            page: 1,
            sub_host: this.sub_host,
            limit: this.pageSize,
          });
      this.dialogVisible6 = false;
    },
    toMonitorLog(item) {
      this.current_domain = item.host + "." + item.sub_host;
      this.dialogVisible7 = true;
    },
    addNewRecord() {
      this.dnstypes = "a";
      this.prefix_host = "";
      this.dnslines = 0;
      this.values = "";
      this.dnsttl = "60";
      // this.dialogVisible = true;
      this.is_edit = false;
      this.is_create = true;
      this.newDataFlag = this.newDataFlag + 1;
      this.newDataList.push({
        newDataFlag: this.newDataFlag,
        is_create: true,
        dnstypes: "a",
        prefix_host: "",
        dnslines: 0,
        values: "",
        dnsttl: "60",
      });
      // if (!this.is_create) {
      // this.is_create = true;
      // this.tableData = [{}].concat(this.tableData);
      // }
    },
    startMyRecord() {},
    // 批量操作
    openMulRecord() {
      this.isMultiple = true;

      new Promise((resolve, reject) => {
        let count = 0;
        const len = this.multipleSelection.length;
        const loadingInstance = Loading.service({ fullscreen: true });
        this.multipleSelection &&
          this.multipleSelection.forEach((item) => {
            if (item.states === 0) {
              startRecord(item.id, true).then((res) => {
                const { code, msg, data } = res || {};
                count++;
                if (code == 1) {
                  if (count == len) {
                    this.getDomainList({ page: 1, sub_host: this.sub_host });
                    this.isMultiple = false;
                    this.multipleSelection = [];
                    loadingInstance.close();
                    resolve();
                  }
                } else {
                  if (count == len) {
                    this.getDomainList({ page: 1, sub_host: this.sub_host });
                    this.isMultiple = false;
                    this.multipleSelection = [];
                    loadingInstance.close();
                    this.$message({
                      message: msg,
                      type: "error",
                    });
                    reject();
                  }
                }
              });
            } else {
              count++;
              if (count == len) {
                this.getDomainList({ page: 1, sub_host: this.sub_host });
                this.isMultiple = false;
                this.multipleSelection = [];
                loadingInstance.close();
                reject();
              }
            }
          });
      });
    },
    pauseMulRecord() {
      this.isMultiple = true;
      new Promise((resolve, reject) => {
        let count = 0;
        const len = this.multipleSelection.length;
        const loadingInstance = Loading.service({ fullscreen: true });
        this.multipleSelection &&
          this.multipleSelection.forEach((item) => {
            if (item.states !== 0) {
              stopRecord(item.id, true).then((res) => {
                const { code, msg, data } = res || {};
                count++;
                if (code == 1) {
                  if (count == len) {
                    this.getDomainList({ page: 1, sub_host: this.sub_host });
                    this.isMultiple = false;
                    this.multipleSelection = [];
                    loadingInstance.close();
                    resolve();
                  }
                } else {
                  if (count == len) {
                    this.getDomainList({ page: 1, sub_host: this.sub_host });
                    this.isMultiple = false;
                    this.multipleSelection = [];

                    loadingInstance.close();
                    this.$message({
                      message: msg,
                      type: "error",
                    });
                    reject();
                  }
                }
              });
            } else {
              count++;
              if (count == len) {
                this.getDomainList({ page: 1, sub_host: this.sub_host });
                this.isMultiple = false;
                this.multipleSelection = [];
                loadingInstance.close();
                reject();
              }
            }
          });
      });
    },
    delMulRecord() {
      this.isMultiple = true;
      this.dialogVisible3 = true;
    },
    cancelEdit(item) {
      if (item && item.is_create) {
        const itemnewDataFlag = item.newDataFlag;
        const newOtherList = this.newDataList.filter((dataItem) => {
          return dataItem.newDataFlag != itemnewDataFlag;
        });
        // if (!newOtherList.length) {
        //   this.is_create = false;
        // }

        this.newDataList = newOtherList;
        // this.tableData.shift();
      }
      this.is_edit = false;
      this.currentEditId = "";
      this.currentEditObj = {};
    },
    getRecordList() {
      recordTypeList().then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.recordList = data.list;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    ChildDomainEdit(item) {
      const { sub_host, record_type, host, dns_line, target, ttl, id } =
        item || {};
      this.itemId = id;
      this.cancelEdit();
      // const hostPrx = (host && host.split(".")) || [];
      // const hostPrx = host && host.substring(0, host.indexOf(sub_host) - 1);
      // const hostPrx = (host && host.split(".")) || [];
      this.sub_host = sub_host;
      this.dnstypes = record_type;
      this.prefix_host = host;
      this.dnslines = dns_line.types;
      this.values = target;
      this.dnsttl = ttl;
      this.currentEditObj = {
        dnstypes: record_type,
        prefix_host: host,
        dnslines: dns_line.types,
        values: target,
        dnsttl: ttl,
      };
      this.currentEditId = item.id;
      // this.dialogVisible = true;
      this.is_edit = true;
    },
    addChildDomain(item) {
      if (this.is_edit) {
        editChildDomain({
          id: this.itemId,
          data: {
            subhost: this.sub_host,
            dnstypes: this.dnstypes,
            prefix_host: this.prefix_host,
            dnslines: this.dnslines,
            values: this.values,
            dnsttl: this.dnsttl,
          },
        }).then((res) => {
          const { code, msg, data } = res || {};
          if (code == 1) {
            // this.dialogVisible = false;
            this.cancelEdit();
            this.getDomainList({ page: 1, sub_host: this.sub_host });
          } else {
            this.$message({
              message: msg,
              type: "error",
            });
          }
        });
      } else {
        addNewSubDomain({
          subhost: this.sub_host,
          dnstypes: item.dnstypes,
          prefix_host: item.prefix_host,
          dnslines: item.dnslines,
          values: item.values,
          dnsttl: item.dnsttl,
        }).then((res) => {
          const { code, msg, data } = res || {};
          if (code == 1) {
            this.cancelEdit(item);
            // this.dialogVisible = false;
            this.getDomainList({ page: 1, sub_host: this.sub_host });
          } else {
            this.$message({
              message: msg,
              type: "error",
            });
          }
        });
      }
    },
    getLineList() {
      dnstlineList().then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.lineList = data.list;
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    ChildDomainRemark(item) {
      this.itemId = item.id;
      this.domainRemark = item.remark;
      this.dialogVisible5 = true;
    },
    toMonitor(item) {
      this.itemId = item.id;
      this.dialogVisible6 = true;
    },
    domainUpdateRemark() {
      remark({ id: this.itemId, remark: this.domainRemark }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.dialogVisible5 = false;
          this.domainRemark = "";
          this.getDomainList({ page: 1, sub_host: this.sub_host });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    getDomainList({ page, sub_host, target, limit, host }) {
      subDomainList({
        page,
        limit: limit || this.pageSize,
        subhost: sub_host,
        target,
        host,
      }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          const { list } = data || {};
          this.total = list.total;
          this.currentPage4 = list.current_page;
          this.tableData =
            list.data &&
            list.data.map((item) => {
              const { host, sub_host, record_type } = item;
              const hostPrx = (host && host.split(".")) || [];
              const newHos =
                host && host.substring(0, host.lastIndexOf(sub_host) - 1);
              return {
                ...item,
                host: newHos,
                record_type: record_type.toUpperCase(),
                hostPrx: hostPrx[0],
              };
            });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    targetChange(e) {
      const type = this.mainhostvalue === 1 ? { target: this.target }: { host: this.target }
      this.getDomainList({
        page: 1,
        sub_host: this.sub_host,
        ...type,
      });
    },
    handleSelectionChange(e) {
      console.log("*********", e);
      this.multipleSelection = e;
    },
    currentChange(e) {
      this.getDomainList({
        page: e,
        sub_host: this.sub_host,
        limit: this.pageSize,
      });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getDomainList({ page: 1, sub_host: this.sub_host, limit: e });
    },
    allAddDomain(e) {
      if (e === "a") {
        this.dialogVisible2 = true;
      }
    },
    toDelRecord(item) {
      this.itemId = item.id;
      this.dialogVisible3 = true;
    },
    toStopRecord(item) {
      stopRecord(item.id).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({
            page: 1,
            sub_host: this.sub_host,
            limit: this.pageSize,
          });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    toStartRecord(item) {
      startRecord(item.id).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.getDomainList({
            page: 1,
            sub_host: this.sub_host,
            limit: this.pageSize,
          });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },
    deleteComfirm() {
      if (!this.domainPwd) {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      if (this.isMultiple) {
        this.deleteAllDomain();
        return;
      }
      delRecord({ id: this.itemId, password: this.domainPwd }).then((res) => {
        const { code, msg, data } = res || {};
        if (code == 1) {
          this.dialogVisible3 = false;
          this.domainPwd = "";
          this.getDomainList({
            page: 1,
            sub_host: this.sub_host,
            limit: this.pageSize,
          });
        } else {
          this.$message({
            message: msg,
            type: "error",
          });
        }
      });
    },

    deleteAllDomain() {
      new Promise((resolve, reject) => {
        let count = 0;
        const len = this.multipleSelection.length;
        const loadingInstance = Loading.service({ fullscreen: true });
        this.multipleSelection &&
          this.multipleSelection.forEach((item) => {
            delRecord({
              id: item.id,
              password: this.domainPwd,
              hasLoading: true,
            }).then((res) => {
              const { code, msg, data } = res || {};
              count++;

              if (code == 1) {
                if (count == len) {
                  this.dialogVisible3 = false;
                  this.domainPwd = "";
                  this.getDomainList({
                    page: 1,
                    sub_host: this.sub_host,
                    limit: this.pageSize,
                  });
                  this.isMultiple = false;
                  this.multipleSelection = [];
                  loadingInstance.close();
                  resolve();
                }
              } else {
                if (count == len) {
                  this.dialogVisible3 = false;
                  this.domainPwd = "";
                  this.getDomainList({
                    page: 1,
                    sub_host: this.sub_host,
                    limit: this.pageSize,
                  });
                  this.isMultiple = false;
                  this.multipleSelection = [];
                  loadingInstance.close();
                  this.$message({
                    message: msg,
                    type: "error",
                  });
                  reject();
                }
              }
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-content {
    width: 1300px;
    margin: 0 auto;
    background-color: white;
    padding: 24px;
  }
  .hen-flex {
    display: flex;
    align-items: center;
  }
  .hen-content {
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .home-width {
    width: 450px;
  }
  .home-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
.item-class {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.margin-12 {
  margin-right: 12px;
}
.curlor {
  cursor: pointer;
}
</style>
