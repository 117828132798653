import axios from 'axios';
// import Cookies from 'js-cookie';
import { Loading } from 'element-ui';
import router from '@/router';
import config from './config.base';
import LOGIN_TOKEN, { getToken, rmToken, setToken } from './storge';
let tempToken = '';

const token = getToken(LOGIN_TOKEN);
/* eslint-disable */
const instance = axios.create({
  headers: { 'content-type': 'application/json' },
  headers: { Authorization:tempToken || token },
});
instance.defaults.baseURL = config.baseURL;
let loadingInstance;
let currhasLoading = false;
// 添加请求拦截器
instance.interceptors.request.use((config) => {
  const token = getToken(LOGIN_TOKEN);
  const { hasLoading } = config || {};
  currhasLoading = hasLoading;
  if (!hasLoading) {
    loadingInstance = Loading.service({ fullscreen: true });
  }
  // 在发送请求之前做些什么
  config.headers.Authorization = tempToken || token;
  return config;
}, (error) => {
    if (!currhasLoading) {
      loadingInstance.close();
    }
  // 对请求错误做些什么
  Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use((response) => {
  if (response.config.url == '/user/auth' || response.config.url == '/user/logout') {
    tempToken = '';
  }
  if (!currhasLoading) {
  loadingInstance.close();
  }
  const { data, headers } = response || {};
  const authorization = headers.authorization;
  if (authorization) {
    tempToken = authorization;
    setToken(authorization);
  }
  if (data.code == 0 && (data.msg == 'token过期' || data.msg == 'token无效' || data.msg == '缺少token')) {
    tempToken = "";
      rmToken();
    router.push({ path: '/login' });
  }
  return data;
}, (error) => {

    if (!currhasLoading) {
    loadingInstance.close();
    }
  return Promise.reject(error);
});

export default instance;
