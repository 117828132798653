export default {
  // baseURL: 'http://cdnfront.web8866.com/api/front',
  baseURL: '/front',
  loginURL: 'http://abc.com',
  uploadImg: 'http://abc.com/image/upload',
  batchImageUpload: 'http://abc.com/product/batchImageUpload/',
};
// scp -r dist/* root@39.96.72.120:/usr/local/nginx/html/cdnweb2/

 let is_ga = 0;
 var myCallBack;
 export const setGa = (val) => {
    is_ga = val;
    myCallBack && myCallBack(is_ga);
 }
 export const getGa = () => {
   return is_ga;
 }

 export const callBack = (call) => {
    call && (myCallBack = call);
 };